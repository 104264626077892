import React, { useEffect, useState } from "react";

import {
  gatherActiveFilters,
  onChangeQuickFilter,
  onColumnFilterChange,
  onFilterChange,
  onRangePickerChange,
  resetAllActiveFilters,
} from "@src/features/table_filtering/utils/base_state_handler_utils";
import {
  clearFilter,
  clearCustomTableFilterProperty,
  setDateFilter,
} from "@src/services/requestOverviewSlice";
import { useAppDispatch, useAppSelector } from "@src/setupStore";
import {
  ActiveFilter,
  REQUEST_OVERVIEW_FILTER_TYPES,
  TabConfig,
} from "@src/types";
import { ColDef, GridApi } from "ag-grid-community";

interface UseStaffingRequestStateChangeHandlerProps {
  gridApi: React.MutableRefObject<GridApi>;
  columnDefs: ColDef[];
  filterCallBack: () => void;
}

interface UseStaffingRequestStateChangeHandlerResponse {
  activeFilters: ActiveFilter[];
  resetActiveFilters: (key?: string, value?: string) => void;
  setTableRendered: () => void;
}

export const useStaffingRequestStateChangeHandler = ({
  gridApi,
  columnDefs,
  filterCallBack,
}: UseStaffingRequestStateChangeHandlerProps): UseStaffingRequestStateChangeHandlerResponse => {
  const [isTableRendered, setIsTableRendered] = useState<boolean>(false);
  const [activeFilters, setActiveFilters] = useState<ActiveFilter[]>([]);
  const dispatch = useAppDispatch();

  const {
    requestOverviewTableState: {
      quickFilter,
      dateFilter,
      customTableFilters,
      currentTabConfig,
      columns,
    },
  } = useAppSelector((state) => state.requestOverviewSlice);

  /**
   * Load filter state from store to grid
   */
  useEffect(() => {
    if (!gridApi.current && !isTableRendered) return;

    onChangeQuickFilter(quickFilter, gridApi.current, filterCallBack);
    onColumnFilterChange(columns, gridApi.current, columnDefs);
    onRangePickerChange(
      dateFilter,
      REQUEST_OVERVIEW_FILTER_TYPES.REQUEST_START,
      gridApi.current,
      filterCallBack
    );
    onTabChange(currentTabConfig, gridApi.current);

    for (const key of Object.keys(customTableFilters)) {
      const values: string[] = customTableFilters[key];
      onFilterChange(values, key, gridApi.current, filterCallBack);
    }

    const activeFilters = gatherActiveFilters(customTableFilters, dateFilter);
    setActiveFilters(activeFilters);
  }, [
    columns,
    dateFilter,
    quickFilter,
    customTableFilters,
    currentTabConfig,
    gridApi.current,
    isTableRendered,
  ]);

  function onTabChange(currentTabConfig: TabConfig, gridApi: GridApi) {
    if (currentTabConfig?.key === "required-action") {
      onFilterChange(
        ["true"],
        REQUEST_OVERVIEW_FILTER_TYPES.REQUIRED_ACTION,
        gridApi,
        null
      );
    } else if (currentTabConfig?.key === "all") {
      onFilterChange(
        ["true", "false"],
        REQUEST_OVERVIEW_FILTER_TYPES.REQUIRED_ACTION,
        gridApi,
        null
      );
    }
  }

  function resetActiveFilterByKey(key: string, value: string) {
    if (key === "date") {
      dispatch(setDateFilter(null));
      onRangePickerChange(
        null,
        REQUEST_OVERVIEW_FILTER_TYPES.REQUEST_START,
        gridApi.current,
        filterCallBack
      );
    } else {
      const valueToBeCleared = { key, value };
      dispatch(clearCustomTableFilterProperty(valueToBeCleared));
    }
  }

  function resetActiveFilters(key?: string, value?: string) {
    if (key !== undefined && value !== undefined) {
      resetActiveFilterByKey(key, value);
    } else {
      onRangePickerChange(
        null,
        REQUEST_OVERVIEW_FILTER_TYPES.REQUEST_START,
        gridApi.current,
        filterCallBack
      );
      resetAllActiveFilters(gridApi.current);
      onTabChange(currentTabConfig, gridApi.current);
      dispatch(clearFilter());
    }

    filterCallBack();
  }

  return {
    activeFilters,
    resetActiveFilters,
    setTableRendered: () => setIsTableRendered(true),
  };
};
