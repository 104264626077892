import React, { useEffect, useRef, useState } from "react";

import { RouteComponentProps } from "@reach/router";
import { CSVParser } from "@src/components/csv_parser";
import { Headline } from "@src/components/headline";
import { LoadingModal } from "@src/components/modals/loading_modal";
import { ROLES } from "@src/constants";
import { AddEmployeeModal } from "@src/features/sap_upload/components/add_employee_modal";
import { SapFilter } from "@src/features/sap_upload/components/sap_filter";
import { UpdateDetailsModal } from "@src/features/sap_upload/components/update_details_modal";
import { UpdateProjectLeadModal } from "@src/features/sap_upload/components/update_project_lead_modal";
import { UploadResult } from "@src/features/sap_upload/components/upload_result";
import {
  detailsRenderer,
  gridOptions,
  infoRenderer,
  statusRenderer,
} from "@src/features/sap_upload/utils/csv_parse_table_column";
import {
  checkIfTeamLeadHasTeam,
  flagDuplicated,
  isRowSelectable,
  readSAPData,
  updateApplicationRoles,
  updateStatistic,
  updateTeamLeadInformation,
  updateVacancies,
} from "@src/features/sap_upload/utils/csv_sap_helper";
import {
  useCheckDeletedSapDataMutation,
  useCheckSapDataMutation,
  useSaveSAPDataMutation,
} from "@src/services/slices/adminApi";
import {
  useLazyGetProjectLeadsQuery,
  useLazyGetTeamLeadsQuery,
} from "@src/services/slices/employeesSlice";
import { useLazyGetStandardRolesQuery } from "@src/services/slices/standardRolesApi";
import {
  Employee,
  ProgressLoadingMessage,
  SapCsvData,
  StandardRole,
  UploadStatistics,
} from "@src/types";
import { sapDTOStatusEnum } from "@src/utils/helper";
import { GridReadyEvent, IRowNode } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { App, Button, Form } from "antd";
import _ from "lodash";

const SapCsvUploadPage = (props: RouteComponentProps) => {
  const gridApi = useRef(null);
  const [form] = Form.useForm();

  const { modal } = App.useApp();

  const initialStatistics: UploadStatistics = {
    totalUpdated: 0,
    totalErrors: 0,
    totalAdded: 0,
    totalDeleted: 0,
    totalUnchanged: 0,
  };

  //modals
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [showAddEmployeeModal, setShowAddEmployeeModal] =
    useState<boolean>(false);
  const [showUpdateProjectLeadModal, setShowUpdateProjectLeadModal] =
    useState<boolean>(false);

  //statistic
  const [uploadStatistics, setUploadStatics] =
    useState<UploadStatistics>(initialStatistics);

  //other
  const [selectedRows, setSelectedRows] = useState<SapCsvData[]>([]);
  const [uploadResponse, setUploadResponse] = useState<SapCsvData[]>([]);
  const [completeUploadResponse, setCompleteUploadResponse] = useState<
    SapCsvData[]
  >([]);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  const [standardRoles, setStandardRoles] = useState<StandardRole[]>([]);
  const [projectLeads, setProjectLeads] = useState<Employee[]>([]);
  const [teamLeads, setTeamLeads] = useState([]);
  const [openedDetailsSapDTO, setOpenedDetailsSapDTO] = useState(null);
  const [loadingMessage, setLoadingMessage] =
    useState<ProgressLoadingMessage>(null);

  const fileIntegrationStatus: string[] = ["Added", "Updated", "Deleted"];
  const tlAvailableFilter = [
    { code: "tlAvailable", name: "available" },
    { code: "tlNotAvailable", name: "not available" },
  ];
  const textArray: string[] = [
    "Click or drag SAP csv file to this area to parse data from csv.",
    "The csv will not be uploaded only the data will be parsed.",
  ];

  const [checkSapData] = useCheckSapDataMutation();
  const [fetchStandardRoles] = useLazyGetStandardRolesQuery();
  const [fetchTeamLeads] = useLazyGetTeamLeadsQuery();
  const [saveSAPData] = useSaveSAPDataMutation();
  const [fetchProjectLeads] = useLazyGetProjectLeadsQuery();
  const [checkDeleteStatus] = useCheckDeletedSapDataMutation();

  useEffect(() => {
    loadTeamLeads();
    loadStandardRoles();

    form.setFieldsValue({
      tlAvailableInDualis: tlAvailableFilter.map(
        (availablityFilter) => availablityFilter.code
      ),
    });
  }, []);

  useEffect(() => {
    updateStatistic(uploadResponse, setUploadStatics, initialStatistics);
  }, [uploadResponse]);

  const loadTeamLeads = async () => {
    fetchTeamLeads(null)
      .unwrap()
      .then((fetchedTeamLeads: any[]) => {
        setTeamLeads(fetchedTeamLeads);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  };

  const loadStandardRoles = async () => {
    fetchStandardRoles(null)
      .unwrap()
      .then((standardRoles: StandardRole[]) => {
        if (standardRoles) {
          setStandardRoles(standardRoles);
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  };

  const frameworkComponents = {
    statusRenderer: statusRenderer,
    detailsRenderer: (params) =>
      detailsRenderer(
        params,
        setOpenedDetailsSapDTO,
        setShowAddEmployeeModal,
        setShowUpdateModal,
        setShowUpdateProjectLeadModal
      ),
    infoRenderer: (params) => infoRenderer(params, gridApi),
  };

  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
  };

  const onSelectionChanged = () => {
    setSelectedRows(gridApi.current.getSelectedRows());
  };

  const onAccept = async () => {
    const progressLoadingMessage: ProgressLoadingMessage = {
      text: "Saving data ...",
      totalRows: selectedRows.length,
      currentRowStatus: selectedRows.length,
      percent: 0,
      type: "progress",
    };

    setLoadingMessage(progressLoadingMessage);
    setIsFileUploading(true);

    const rowNodes: IRowNode[] = [];
    gridApi.current.forEachNode((node: IRowNode) => rowNodes.push(node));

    for (const [index, selectedRow] of selectedRows.entries()) {
      await processUpdate(selectedRow, rowNodes);

      const leftSelectedRow: number = selectedRows.length - index;
      const percent: number =
        100 - Math.trunc((leftSelectedRow * 100) / selectedRows.length);

      setLoadingMessage((progressLoadingMessage: ProgressLoadingMessage) => ({
        ...progressLoadingMessage,
        percent: percent,
        currentRowStatus: leftSelectedRow,
      }));
    }

    setIsFileUploading(false);
  };

  function handleSuccessfulUpdate(
    selectedRow: SapCsvData,
    sapCsvDataResult: Employee,
    rowNodes: IRowNode[]
  ) {
    switch (selectedRow.status) {
      case sapDTOStatusEnum.ADDED:
        enableEmployeesOfTeamLeadForAdd(selectedRow, rowNodes);
        break;
      case sapDTOStatusEnum.DELETED:
        enableRelatedTeamLeadForDeletion(selectedRow, rowNodes);
        break;
    }

    //update the team leads list with the new team lead
    if (
      selectedRow.applicationRoles.includes(ROLES.TEAM_LEAD) &&
      selectedRow.status === sapDTOStatusEnum.ADDED
    ) {
      setTeamLeads((teamLeadsObjects) => [
        ...teamLeadsObjects,
        sapCsvDataResult,
      ]);
    }

    gridApi.current.applyTransaction({ remove: [selectedRow], force: true });

    setUploadStatics((uploadStatics: UploadStatistics) => ({
      ...uploadStatics,
      ["total" + selectedRow.status]:
        uploadStatics["total" + selectedRow.status] - 1,
    }));
  }

  function handleErrorUpdate(rows: IRowNode[], selectedRow: SapCsvData, error) {
    setUploadStatics((uploadStatics: UploadStatistics) => ({
      ...uploadStatics,
      totalErrors: uploadStatics.totalErrors + 1,
    }));

    const rowNode = rows.find(
      (row: IRowNode) => row.data.uiNumber === selectedRow.uiNumber
    );
    const errorMessage: string = error?.data?.message || "something went wrong";

    rowNode.setDataValue("status", sapDTOStatusEnum.ERROR);
    rowNode.data.errorMessage.push(errorMessage);
    gridApi.current.applyTransaction({ update: [rowNode.data], force: true });
  }

  const processUpdate = async (
    selectedRow: SapCsvData,
    rowNodes: IRowNode[]
  ): Promise<SapCsvData> => {
    const selectedRowCopy = _.cloneDeep(selectedRow);

    await saveSAPData(selectedRow)
      .unwrap()
      .then((sapCsvDataResult: Employee) => {
        handleSuccessfulUpdate(selectedRow, sapCsvDataResult, rowNodes);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch((error) => {
        handleErrorUpdate(rowNodes, selectedRow, error);
      });

    return selectedRowCopy;
  };

  /**
   * Enable the employee to be selected for add. When a team for the employee was added
   * @param addedTeamLead
   * @param rowData
   */
  const enableEmployeesOfTeamLeadForAdd = (
    addedTeamLead: SapCsvData,
    rowNode: IRowNode[]
  ) => {
    //get all employees of the added team lead
    const employeesToAdd: IRowNode[] = rowNode.filter(
      (node: IRowNode) => node.data.uiNumberBoss === addedTeamLead.uiNumber
    );

    for (const employeeNode of employeesToAdd) {
      //check if the employee is a team lead
      const isEmployeeNoTeamLead = !employeeNode.data.isTeamLead;

      const newAddedTeamLeadWithRoles =
        employeeNode.data.isTeamLead &&
        employeeNode.data.standardRoles.length !== 0 &&
        employeeNode.data.status === sapDTOStatusEnum.ADDED;

      //should be selectable if the employee is not a team lead or if the employee is a team lead and has no roles assigned
      if (
        isEmployeeNoTeamLead ||
        newAddedTeamLeadWithRoles ||
        employeeNode.data.status === sapDTOStatusEnum.UPDATED
      ) {
        employeeNode.setSelected(true);
      }

      gridApi.current.redrawRows({ rowNodes: [employeeNode] });
    }
  };

  /**
   * should get the responsible team lead and check if the team lead of the employee is flagged for deletion.
   * and is a team lead of other employee. Because if the team lead is flagged for deletion and has employees assigned
   * than the team lead should not be deletable
   * @param processedEmployee
   * @param rowNodes
   */
  const enableRelatedTeamLeadForDeletion = (
    processedEmployee: SapCsvData,
    rowNodes: IRowNode[]
  ) => {
    // get the team lead of the employee to unlock for deletion
    const teamLeadToUnlockForDeletion = rowNodes.find((row: IRowNode) => {
      return (
        row.data.status === sapDTOStatusEnum.DELETED &&
        row.data.uiNumber === processedEmployee.uiNumberBoss
      );
    });

    //early return if employee is not unlocked for selection
    if (!teamLeadToUnlockForDeletion) return;

    //otherwise unlock employee
    const node: IRowNode = rowNodes.find(
      (node: IRowNode) =>
        node.data.uiNumber === teamLeadToUnlockForDeletion.data.uiNumber
    );
    if (!node) return;

    //has the team lead other employees assigned
    node.data.hasTeamleadTeam = rowNodes.some(
      (employee: IRowNode) =>
        employee.data.uiNumberBoss ===
          teamLeadToUnlockForDeletion.data.uiNumber &&
        employee.data.uiNumber !== processedEmployee.uiNumber
    );

    // enable checkbox of the associated tl for deletion when the team lead has no team and no roles assigned
    if (!node.data.hasEmployeeRolesAssigned && !node.data.hasTeamleadTeam) {
      node.setSelected(true);
    }
  };

  const checkAccept = () => {
    const deletedSelectedRows = selectedRows.filter(
      (row: SapCsvData) => row.status === sapDTOStatusEnum.DELETED
    );

    const attentionMessage: string =
      deletedSelectedRows.length >= 1
        ? "ATTENTION: You selected also entries for deletion."
        : "";

    const title = `You selected: ${selectedRows.length} rows`;
    const warning = `${attentionMessage} Are you sure you want to process them all?`;

    confirmationModal(title, warning);
  };

  const onCancel = () => {
    setUploadResponse([]);
    setSelectedRows([]);
    setCompleteUploadResponse([]);
    setUploadStatics(initialStatistics);
    setLoadingMessage(null);

    form.setFieldsValue({
      tlAvailableInDualis: tlAvailableFilter.map(
        (availablityFilter) => availablityFilter.code
      ),
    });
  };

  const sendData = async (list: SapCsvData[]) => {
    let listToCheck: SapCsvData[];

    await fetchProjectLeads(null)
      .unwrap()
      .then((projectLeads: Employee[]) => {
        setProjectLeads(projectLeads);
        listToCheck = addMissingValues(list, projectLeads);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});

    const chunkSize = 100;
    const uploadResponse: SapCsvData[] = [];
    let percent = 0;

    //filter out employees with errors to not send them to the backend because they are not valid
    const validSapEntries = listToCheck.filter(
      (employee) => employee.status !== sapDTOStatusEnum.ERROR
    );

    for (let i = 0; i < validSapEntries.length; i += chunkSize) {
      const chunk = validSapEntries.slice(i, i + chunkSize);
      const sapCheckResult: any = await checkSapData(chunk);

      try {
        percent = Math.round((i / validSapEntries.length) * 100);

        setLoadingMessage((message: ProgressLoadingMessage) => ({
          ...message,
          type: "progress",
          percent: percent,
          currentRowStatus: i,
          totalRows: validSapEntries.length,
        }));

        uploadResponse.push(...sapCheckResult.data);
      } catch (error) {
        //the error is already handled in the slice
        // eslint-disable-next-line @typescript-eslint/no-empty-function
      }
    }

    // add employees with errors to the response to display them in the table
    // after the upload for the valid entries finished
    uploadResponse.push(
      ...list.filter(
        (employee: SapCsvData) => employee.status === sapDTOStatusEnum.ERROR
      )
    );

    setLoadingMessage((message: ProgressLoadingMessage) => ({
      ...message,
      type: "loading",
    }));

    //check if team members who are flagged for deletion has still roles assigned. If not than make the checkbox selectable
    await checkDeletedEmployees(uploadResponse);
  };

  const checkDeletedEmployees = async (uploadResponse: SapCsvData[]) => {
    const employeesFromCSV = _.uniq(
      uploadResponse.map((employee) => employee.uiNumber)
    );

    await checkDeleteStatus(employeesFromCSV)
      .unwrap()
      .then((deletedSAPDTOs: SapCsvData[]) => {
        const deletedSAPDtoCopy = _.cloneDeep(deletedSAPDTOs);
        checkIfTeamLeadHasTeam(deletedSAPDtoCopy);

        uploadResponse.push(...deletedSAPDtoCopy);

        setUploadResponse(_.cloneDeep(uploadResponse));
        setCompleteUploadResponse(_.cloneDeep(uploadResponse));
        setIsFileUploading(false);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  };

  const addMissingValues = (
    sapCsvDataEntries: SapCsvData[],
    projectLeads: Employee[]
  ) => {
    const count: string[] = _.countBy(sapCsvDataEntries, "uiNumber");

    for (const sapEmployee of sapCsvDataEntries) {
      sapEmployee.errorMessage = !sapEmployee.errorMessage
        ? []
        : sapEmployee.errorMessage;

      // add information for a vacancy
      updateVacancies(sapEmployee);

      // add information of the employees team lead
      updateTeamLeadInformation(sapCsvDataEntries, sapEmployee);

      // add information of the employees application roles
      updateApplicationRoles(sapEmployee, sapCsvDataEntries, projectLeads);

      // flag duplicated entries and add error message
      flagDuplicated(count, sapEmployee, sapCsvDataEntries);
    }

    return sapCsvDataEntries;
  };

  const confirmationModal = (title: string, warning: string) => {
    modal.confirm({
      autoFocusButton: null,
      title: title,
      content: warning,
      okText: "Yes",
      cancelText: "No",
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      async onOk() {
        await onAccept();
      },
    });
  };

  return (
    <>
      {showUpdateProjectLeadModal && (
        <UpdateProjectLeadModal
          setModalVisible={setShowUpdateProjectLeadModal}
          modalVisible={showUpdateProjectLeadModal}
          openedDetailsSapDTO={openedDetailsSapDTO}
          projectleads={projectLeads}
          mainGridApi={gridApi}
        />
      )}

      {showUpdateModal && (
        <UpdateDetailsModal
          setModalVisible={setShowUpdateModal}
          modalVisible={showUpdateModal}
          openedDetailsSapDTO={_.cloneDeep(openedDetailsSapDTO)}
        />
      )}

      {isFileUploading && (
        <LoadingModal
          modalVisible={isFileUploading}
          loadingMessage={loadingMessage}
        />
      )}

      <CSVParser
        setIsFileUploading={setIsFileUploading}
        isFileUploading={isFileUploading}
        setLoadingMessage={setLoadingMessage}
        sendData={sendData}
        onCancel={onCancel}
        title="Upload new SAP File"
        textArray={textArray}
        readData={readSAPData}
      />
      <AddEmployeeModal
        showAddEmployeeModal={showAddEmployeeModal}
        setShowAddEmployeeModal={setShowAddEmployeeModal}
        standardroles={_.cloneDeep(standardRoles)}
        openedDetailsSapDTO={openedDetailsSapDTO}
        setOpenedDetailsSapDTO={setOpenedDetailsSapDTO}
        gridapi={gridApi.current}
        teamleads={teamLeads}
        setStandardRoles={setStandardRoles}
      />

      {completeUploadResponse?.length !== 0 && (
        <>
          <Headline className="result-label" title="Result" />
          <div className="upload-result-section">
            <div className="info-section-with-btn">
              <SapFilter
                tlAvailableFilter={tlAvailableFilter}
                fileIntegrationStatus={fileIntegrationStatus}
                completeUploadResponse={completeUploadResponse}
                setUploadResponse={setUploadResponse}
                gridApi={gridApi}
              />

              <div className="btn-section">
                <Button
                  type="primary"
                  disabled={selectedRows.length === 0}
                  onClick={checkAccept}
                  size="large"
                >
                  Accept
                </Button>
              </div>
            </div>

            <UploadResult
              uploadStatistics={uploadStatistics}
              gridApi={gridApi}
            />

            <div
              className={
                selectedRows.length === 0
                  ? "selected-rows-info-hidden"
                  : "selected-rows-info"
              }
            >
              Selected: <b> {selectedRows.length}</b>
            </div>

            {completeUploadResponse.length !== 0 && (
              <div className="ag-theme-alpine header-white">
                <AgGridReact
                  onGridReady={onGridReady}
                  components={frameworkComponents}
                  rowData={uploadResponse}
                  isRowSelectable={isRowSelectable}
                  onSelectionChanged={onSelectionChanged}
                  gridOptions={gridOptions as any}
                  onGridSizeChanged={(props) => props.api.sizeColumnsToFit()}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SapCsvUploadPage;
